// /*eslint-disable no-unused-vars*/
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  TablePagination,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  Button,
  Checkbox,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Page from 'src/components/Page';
import PageHeading from 'src/components/PageHeading';
import React, { useEffect, useState, useMemo } from 'react';
import { API_URL } from 'src/utils/constants';
import axios from 'axios';
import { Loading } from 'src/components/Loading';
import { Link } from 'react-router-dom';

const placeIds = [{ label: 'Rishikesh', id: 'p2975b6' }, { label: 'Ladakh', id: 'paf3c28' }];

const columns = [
  { id: 'place', label: 'Place', sortable: true, searchable: true },
  { id: 'title', label: 'Title', sortable: true, searchable: true },
  { id: 'admin', label: 'Admin', sortable: true, searchable: true },
  { id: 'phone', label: 'Phone', sortable: true, searchable: true },
  { id: 'code', label: 'Code', sortable: true, searchable: true },
];

const createData = (id, place, title, admin, phone, code) => {
  return { id, place, title, admin, phone, code };
};

function ListPartnersPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [partner, setPartner] = useState({
    phone: '',
    name: '',
    placeId: '',
    businessTitle: '',
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_URL}/admin/mb/partners`);
      const data = response.data;
      const formattedData = data.map((row) => createData(
        row.id,
        row.place,
        row.title,
        row.admin,
        row.phone,
        row.code,
      ));
      setRows(formattedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch data:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPartner((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      // Replace 'your_post_api_endpoint' with your actual POST API endpoint
      const response = await fetch(`${API_URL}/admin/mb/partners`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(partner),
      });
      if (response.ok) {
        handleClose(); // Close the dialog on successful POST
        setIsLoading(false);
        location.reload();
      } else {
        console.error('Failed to submit partner:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting partner:', error);
    }
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('product');
  const [visibleColumnIds, setVisibleColumnIds] = useState(columns.map((col) => col.id));
  const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
  const [searchTerm, setSearchTerm] = useState('');
  const [dense, setDense] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleColumnVisibility = (id) => {
    setVisibleColumnIds((prev) => {
      const newColumnIds = prev.includes(id)
        ? prev.filter((colId) => colId !== id)
        : [...prev, id].sort((a, b) => columns.findIndex(col => col.id === a) - columns.findIndex(col => col.id === b));
      setColumnOrder(newColumnIds);
      return newColumnIds;
    });
  };


  const handleDenseChange = (event) => {
    setDense(event.target.checked);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      const matchesSearch = Object.keys(row).some(
        (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
      );
      const matchesCategory = categoryFilter === 'All' || row.place === categoryFilter;
      return matchesSearch && matchesCategory;
    });
  }, [searchTerm, categoryFilter, rows]);

  const sortedRows = useMemo(() => {
    return filteredRows.sort((a, b) => {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      // Handle different data types for proper sorting
      if (orderBy === 'date') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
        // } else if (orderBy === 'stock') {
        //     aValue = parseInt(aValue.replace(/\D/g, ''), 10);
        //     bValue = parseInt(bValue.replace(/\D/g, ''), 10);
      } else {
        aValue = aValue?.toString().toLowerCase();
        bValue = bValue?.toString().toLowerCase();
      }

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredRows, orderBy, order, rows]);

  const paginatedRows = useMemo(() => {
    return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedRows, page, rowsPerPage, filteredRows, orderBy, order, rows]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Page title="Partners List">
      <Container>
        <PageHeading heading="Partners List" />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4, gap: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
          />

          <FormControl variant="outlined" size="small" sx={{ width: '200px' }}>
            <InputLabel>Place</InputLabel>
            <Select
              value={categoryFilter}
              onChange={handleCategoryChange}
              input={<OutlinedInput label="Category" />}
            >
              <MenuItem value="All">All</MenuItem>
              {Array.from(new Set(rows.map((row) => row.place))).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            sx={{ m: 0 }}
            control={<Switch checked={dense} onChange={handleDenseChange} />}
            label="Dense padding"
          />
          <IconButton
            aria-label="settings"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {columns.map((column) => (
              <MenuItem key={column.id} onClick={() => handleColumnVisibility(column.id)}>
                <Checkbox
                  checked={visibleColumnIds.includes(column.id)}
                />
                {column.label}
              </MenuItem>
            ))}
          </Menu>
          <Button variant='contained' color="error" onClick={handleClickOpen}>
            Add Partner
          </Button>
        </Box>

        <TableContainer>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                {columnOrder.map((colId) => {
                  const column = columns.find((col) => col.id === colId);
                  if (!column || !visibleColumnIds.includes(column.id)) return null;
                  return (
                    <TableCell key={column.id}>
                      {column.sortable ? (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={(event) => handleRequestSort(event, column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  );
                })}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row, index) => (
                <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                  {columnOrder.map((colId) => {
                    const column = columns.find((col) => col.id === colId);
                    if (!column || !visibleColumnIds.includes(column.id)) return null;
                    return (
                      <TableCell key={column.id}>{row[column.id]}</TableCell>
                    );
                  })}
                  <TableCell>

                    <Link target='_blank' to={`/partner/${row.id}/bookings`}>
                      <Button variant='contained' color="error">
                        View
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Container>
      {/* Dialog for Adding Partner */}
      {open && <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a New Partner</DialogTitle>
        <DialogContent>
          <div className='flex gap-4 items-center'>
            <label className='font-semibold m-0'>Place</label>
            <Autocomplete
              id="combo-box-demo"
              options={placeIds}
              sx={{ width: 340 }}
              renderInput={(params) => <TextField {...params} />}
              onChange={(_, newValue) => {
                setPartner((prev) => ({ ...prev, placeId: newValue?.id || '' }));
              }}
            />
          </div>
          <TextField autoFocus margin="dense" name="name" placeholder="name" type="text" fullWidth variant="standard" value={partner.name} onChange={handleInputChange} />
          <TextField margin="dense" name="phone" placeholder="Phone" type="text" fullWidth variant="standard" value={partner.phone} onChange={handleInputChange} />
          <TextField margin="dense" name="businessTitle" placeholder="Business Title" type="text" fullWidth variant="standard" value={partner.businessTitle} onChange={handleInputChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={isLoading} onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>}
    </Page>
  );
}

export default ListPartnersPage;
