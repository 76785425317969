

import React, { useState, useEffect, useMemo } from 'react';
import Page from '../../components/Page';
import {
    Box,
    Button,
    Container,
    Divider,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatCurrency, formatLedgerCurrency } from 'src/utils/formatCurrency';
import { partnerLedgerLabels, partnerLedgerStatusColors } from 'src/utils/statuses/partners';
import axiosMB from 'src/utils/axios/interceptors';

const PartnerLedgerDetails = () => {
    const { pathname } = useLocation();
    const partnerId = pathname.split("/")[2];
    const [loading, setLoading] = useState(true);
    const [bookingDetails, setBookingDetails] = useState([]);
    const [value, setValue] = useState(0);
    const [sortBy, setSortBy] = useState("endDate");
    const [sortDirection, setSortDirection] = useState("dec");
    const [financeDetails, setFinanceDetails] = useState({
        pendingTotal: 0,
        approvalPendingTotal: 0,
        settlementPendingTotal: 0,
        settlementDoneTotal: 0,
    });
    const [bookings, setBookings] = useState({
        settlementDone: [],
        settlementPending: [],
    });

    const sortDirectionFunction = (a, b) => {
        if (sortDirection == "asc") {
            return new Date(a[sortBy]) - new Date(b[sortBy])
        }
        return new Date(b[sortBy]) - new Date(a[sortBy])
    }

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_URL}/partner/bookings?partnerId=${partnerId}`);
                const data = await response.json();
                setBookingDetails(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch bookingDetails:', error);
            }
        };
        fetchBookings();
    }, []);

    const pendingFilter = (booking) => {
        if (booking.endDate < new Date().toISOString() &&
            !(partnerLedgerLabels[booking.status] == "Cancelled" || partnerLedgerLabels[booking.status] == "Settlement Done") &&
            booking.status !== "partner-settlement-pending" &&
            booking.status !== "partner-settlement-acknowledgement-pending"
        ) {
            return booking
        }
    }
    const settlementDoneFilter = (booking) => {
        if (partnerLedgerLabels[booking.status] === "Cancelled" || partnerLedgerLabels[booking.status] === "Settlement Done") {
            return booking
        }
    }
    const settlementPendingFilter = (booking) => {
        if (booking.status === "partner-settlement-pending") {
            return booking
        }
    }
    const approvalPendingFilter = (booking) => {
        if (booking.status === "partner-settlement-acknowledgement-pending") {
            return booking
        }
    }


    useMemo(() => {
        const totalData = {
            pendingTotal: 0,
            approvalPendingTotal: 0,
            settlementPendingTotal: 0,
            settlementDoneTotal: 0,
        }
        const pending = bookingDetails
            .filter(pendingFilter)
            .sort(sortDirectionFunction)
            .map((booking) => {
                totalData.pendingTotal += booking.amountPartnerSettlementLeft
                return <PartnerLedgerCard key={booking.id} booking={booking} />
            })


        const approvalPending = bookingDetails
            .filter(approvalPendingFilter)
            .sort(sortDirectionFunction)
            .map((booking) => {
                totalData.approvalPendingTotal += booking.amountPartnerSettlementLeft
                return <PartnerLedgerCard key={booking.id} booking={booking} />
            })

        const settlementPending = bookingDetails
            .filter(settlementPendingFilter)
            .sort(sortDirectionFunction)
            .map((booking) => {
                totalData.settlementPendingTotal += booking.amountPartnerSettlementLeft
                return <PartnerLedgerCard key={booking.id} booking={booking} />
            })

        const settlementDone = bookingDetails
            .filter(settlementDoneFilter)
            .sort(sortDirectionFunction)
            .map((booking) => {
                totalData.settlementDoneTotal += partnerLedgerLabels[booking.status] === "Cancelled" ? 0 : booking.amountPartnerSettlementLeft
                return <PartnerLedgerCard key={booking.id} booking={booking} />
            })

        setFinanceDetails(totalData)
        setBookings({
            pending: pending.length ? pending : null,
            approvalPending: approvalPending.length ? approvalPending : null,
            settlementPending: settlementPending.length ? settlementPending : null,
            settlementDone: settlementDone.length ? settlementDone : null,
        })
    }, [bookingDetails, value, sortBy, sortDirection]);

    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                setSortBy("endDate")
                setSortDirection("asc")
                break;
            case 1:
                setSortBy("endDate")
                setSortDirection("dec")
                break;
            default:
                break;
        }

        setValue(newValue);
    };

    return (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Ledger
                </Typography>
                {!loading ? (<><Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                        variant="scrollable"    // Enables horizontal scrolling
                        scrollButtons="auto"    // Adds scroll buttons when necessary
                        allowScrollButtonsMobile  // Makes sure buttons appear on mobile
                    >
                        <Tab label="Pending" {...a11yProps(0)} />
                        <Tab label="Approval Pending" {...a11yProps(1)} />
                        <Tab label="Payment Pending" {...a11yProps(2)} />
                        <Tab label="Completed" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                    <CustomTabPanel value={value} index={0}>
                        {bookings.pending ? <>
                            {bookings.pending}
                            {financeDetails.pendingTotal > 0 && <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>Total Pending: {formatLedgerCurrency(financeDetails.pendingTotal)}</div>}
                        </>
                            : <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>No Pending Bookings</div>}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {bookings.approvalPending ? <>
                            {bookings.approvalPending}
                            {financeDetails.approvalPendingTotal > 0 && <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>Total Pending: {formatLedgerCurrency(financeDetails.approvalPendingTotal)}</div>}
                        </>
                            : <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>No Pending Bookings</div>}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        {bookings.settlementPending ? <>
                            {bookings.settlementPending}
                            {financeDetails.settlementPendingTotal > 0 && <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>Total Pending: {formatLedgerCurrency(financeDetails.settlementPendingTotal)}</div>}
                        </> : <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>No Pending Bookings</div>}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        {bookings.settlementDone ? <>
                            {bookings.settlementDone}
                            {financeDetails.settlementDoneTotal > 0 && <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>Total Paid: {formatLedgerCurrency(financeDetails.settlementDoneTotal)}</div>}
                        </> : <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>No Completed Bookings</div>}
                    </CustomTabPanel></>) :
                    (<div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>Loading...</div>)
                }
            </Container>

        </Page>
    );
};

const phoneNumberMask = ({ startDate, endDate, phoneNumber }) => {
    if (phoneNumber) {
        const twoDaysFromToday = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
        if (endDate) {
            if (new Date(startDate) >= twoDaysFromToday || new Date() >= new Date(endDate)) {
                return phoneNumber.split('').map((char, index) => {
                    if (index > 2 && index < phoneNumber.length - 4) {
                        return '*';
                    }
                    return char;
                }).join('');
            }
        } else if (new Date(startDate) >= twoDaysFromToday) {
            return phoneNumber.split('').map((char, index) => {
                if (index > 2 && index < phoneNumber.length - 4) {
                    return '*';
                }
                return char;
            }).join('');
        }
        return phoneNumber;
    }
    return phoneNumber;
}

const PartnerLedgerCard = ({ booking }) => {
    const {
        id,
        startDate,
        endDate,
        dateLabel,
        status,
        guestName,
        guestPhone,
        amountPartnerSettlementActual,
        amountPartnerToBeCollected,
        amountPartnerSettlementLeft,
    } = booking;
    const updateBooking = async (changes) => {
        const { id: bookingId, changedData: updateFields } = changes
        try {
            const response = await axiosMB.patch(
                `${API_URL}/admin/mb/bookings/${bookingId}`,
                { updateFields },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                location.reload();
            }
        } catch (error) {
            console.error('Failed to update booking finance:', error);
        }
    };

    return (
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 bg-white shadow-md p-4'>
            <div className='flex sm:flex-col gap-2 items-start max-sm:justify-between'>
                <div className='flex flex-col  '>
                    <p className='font-bold'>{guestName}</p>
                    <p>{phoneNumberMask({ startDate, endDate, phoneNumber: guestPhone })}</p>
                    <p className='whitespace-nowrap mt-2'>{dateLabel}</p>
                </div>
                <p style={{ borderColor: partnerLedgerStatusColors[status], color: partnerLedgerStatusColors[status] }} className='border-2 px-4 py-2 rounded-lg text-center h-10 sm:mt-auto'>{partnerLedgerLabels[status]}</p>
            </div>
            <Divider className='col-span-full sm:hidden w-full h-1' orientation="horizontal" />
            <div className='flex flex-col'>
                <div className='flex items-center justify-between font-bold gap-2 whitespace-nowrap'><p className='' >Your Price: </p>{formatCurrency(amountPartnerSettlementActual)}</div>
                <Divider className='col-span-full' orientation="horizontal" />
                <div className='flex items-center justify-between font-bold gap-2'><p className='' >Collect from Customer:</p> {formatCurrency(amountPartnerToBeCollected)}</div>
                <Divider className='col-span-full' orientation="horizontal" />
                <div className='flex items-center justify-between font-bold gap-2'><p className='' >Settlement:</p> {formatCurrency(amountPartnerSettlementLeft)}</div>
            </div>
            <Divider className='col-span-full sm:hidden w-full h-1' orientation="horizontal" />
            <div className='flex flex-col items-center justify-start w-full gap-2'>
                <h1 className='font-bold'>Action</h1>
                {status === "partner-settlement-acknowledgement-pending" ? (
                    <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap'
                        onClick={async () => {
                            await updateBooking({ id, changedData: { status: 'partner-settlement-pending' } })
                        }}>
                        Accept
                    </Button>
                ) : <></>
                }
            </div>
            <Divider className='col-span-full sm:hidden w-full h-1' orientation="horizontal" />
            <Divider className='col-span-full' orientation="horizontal" />

        </div>
    );
}



PartnerLedgerCard.propTypes = {
    booking: PropTypes.object.isRequired,
};
export default PartnerLedgerDetails;


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box className='grid grid-cols-1 gap-4 p-2' sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
