import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
    Autocomplete,
    TextField,
} from '@mui/material';
const ACTIVITY_LOCATION_TYPE = [
    'starting-location',
    'drop-location',
    'related-location',
    'nearby-popular-location',
    'primary-location',
]

export default function AddActivityLocation({ location, updateLocations }) {
    const [locationType, setLocationType] = useState(location?.location?.type || ACTIVITY_LOCATION_TYPE[0]);
    const [locationDetails, setLocationDetails] = useState({
        shortAddress: location?.location?.shortAddress || '',
        address: location?.location?.address || '',
        lat: location?.location?.lat || 0,
        long: location?.location?.long || 0,
    });

    useEffect(() => {
        updateLocations({
            index: location.index,
            type: locationType,
            location: {
                locIndex: { coordinates: [parseFloat(locationDetails.long), parseFloat(locationDetails.lat)] },
                shortAddress: locationDetails.shortAddress,
                address: locationDetails.address,
                lat: parseFloat(locationDetails.lat),
                long: parseFloat(locationDetails.long),
            }
        })
    }, [locationDetails, locationType])
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'lat' || name === 'long') {
            const numValue = value.replace(/[^0-9.+-]/g, '');
            setLocationDetails({ ...locationDetails, [name]: numValue });
        } else {
            setLocationDetails({ ...locationDetails, [e.target.name]: e.target.value });
        }
    };
    return (
        <div className="grid grid-cols-2 gap-x-5">
            <div className="col-span-full grid grid-cols-1 gap-3">
                <label className='font-bold m-0'>Type</label>
                <Autocomplete
                    id="combo-box-demo"
                    options={ACTIVITY_LOCATION_TYPE}
                    sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="Location Type" />}
                    onChange={(_, newValue) => {
                        setLocationType(newValue);
                    }}
                    value={locationType}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Address</label>
                <input type="text" name="address" value={locationDetails.address}
                    onChange={handleChange}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Short Address</label>
                <input type="text" name="shortAddress" value={locationDetails.shortAddress}
                    onChange={handleChange}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Latitude</label>
                <input type="text" name="lat" value={locationDetails.lat}
                    onChange={handleChange}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Longitude</label>
                <input type="text" name="long" value={locationDetails.long}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
}

AddActivityLocation.propTypes = {
    location: PropTypes.object,
    updateLocations: PropTypes.func,
};