/* eslint-disable */
import _ from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';
import CircleIcon from '@mui/icons-material/Circle';
import './../components/gallery/gallery.css';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material';

// components
import Page from '../components/Page';
import ActivityList from '../components/activityList/activityList';
import CreateGenericActivityParams from '../components/activityList/createGenericActivityParams';

import { useParams } from 'react-router-dom';
import { API_URL } from 'src/utils/constants';
import { ActivityBanners } from 'src/components/banners/activityBanners';

export default function EditActivityPage() {
  const [activities, setActivities] = useState([]);
  const [initialActivity, setInitialActivitity] = useState([]);
  const [newVariants, setNewVariants] = useState([]);
  const [productData, setProductData] = useState([]);

  const [banners, setBanners] = useState([]);
  const [bannerSections, setBannerSelection] = React.useState([]);

  const [activityCommonParams, setActivityCommonParams] = useState({});

  const [textAreaValue, setTextAreaValue] = useState('');
  const [isLoading, setLoader] = useState(false);

  //   let activityCommonParams = {};

  //   const setActivityCommonParams = (value) => {
  //     activityCommonParams = value;
  //   };
  const { slug } = useParams();
  // const getActivities = async () => {
  //   try {
  //     const res = await axios.get(`${API_URL}/activity/mb`);
  //     setActivities(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getActivity = async () => {
    try {
      const res = await axios.get(`${API_URL}/activity/mb/${slug}`);
      setInitialActivitity(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);

  const onBannerChanged = (event) => {
    const urls = event.target.value;
    const _banners = urls.split('\n');
    setBanners(_banners);
  };

  const openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  };

  const onSelecteActivityChange = (_, newValue) => {
    const _activity = getActivity(newValue.slug);
  };

  const handleActivityBannerChange = (newBanners, bannerSections) => {
    setBanners(newBanners);
    setBannerSelection(bannerSections);
  };

  const saveFinalData = async () => {
    setLoader(true);

    try {
      console.log(activityCommonParams);
      const body = {
        _id: initialActivity._id,
        ...activityCommonParams,
        newVariants: newVariants,
        banners: banners.length ? banners : undefined,
        bannerSections: bannerSections.length ? bannerSections : undefined,
      };
      console.log(body);
      const res = await axios
        .put(`${API_URL}/activity/mb`, body)
        .then((response) => {
          setLoader(false);
          openInNewTab(`${API_URL}/activity/${response.data.slug}`);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title="Edit Activity">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Edit Activity
        </Typography>
        {/* <div
          style={{
            width: '60%',
            margin: '0px 20px 0px 0px',
            display: 'inline-grid',
          }}
        >
          <label>Type</label>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={activities}
            sx={{ width: 340 }}
            renderInput={(params) => <TextField {...params} label="" />}
            onChange={onSelecteActivityChange}
          />
        </div> */}

        <div style={{ margin: '0px' }}>
          <CreateGenericActivityParams
            initialData={initialActivity}
            passActivityData={setActivityCommonParams}
          />
        </div>

        <div style={{ paddingTop: 32 }}>
          <label>
            <h3>Banners</h3>
          </label>
          <ActivityBanners
            handleActivityBannerChange={handleActivityBannerChange}
            initialUrls={initialActivity.bannersUrlString}
          />
        </div>

        <div className="finalsave">
          <Button
            className="save"
            disabled={isLoading}
            enabled
            onClick={saveFinalData}
          >
            SAVE
          </Button>
          {isLoading ? <CircularProgress /> : null}
        </div>
      </Container>
    </Page>
  );
}
