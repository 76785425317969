import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import AdminLayout from './layouts/dashboard';
import PartnerLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
// import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import ActivityPage from './pages/Activity';
import EditActivityPage from './pages/EditActivity';
import Activites from './pages/Activities';
import ListOrdersPage from './pages/Orders/ListOrdersPage';
import MakeBooking from './pages/Bookings/MakeBooking';
import CreateOrderPage from './pages/Orders/CreateOrderPage';
import UpdateMasterInventoryPage from './pages/Inventory/UpdateMasterInventory';
import UpdateInventoryPage from './pages/Inventory/UpdateInventory';
import ListPartnersPage from './pages/Partners/ListPartnersPage';
import LadakhItineraryPage from './pages/LadakhItineraryPage';
import ListBookingsPage from './pages/Orders/ListBookings';
import BookingDetailsPage from './pages/Orders/BookingDetails';
import CouponDetails from './pages/Coupons/ListCouponPage';
import EditItinerary from './pages/EditItinerary';
import EditFulfillmentPolicy from './pages/EditFulfillmentPolicy';
import ListPartnersEmployeePage from './pages/Employee/ListPartnersEmployeePage';
// Partner
import PartnerBookingDetails from './Partner/Bookings/PartnerBookingDetails';
import PartnerEmployeeDetails from './Partner/Employees/PartnerEmployeeDetails';
import PartnerLedgerDetails from './Partner/Ledger/PartnerLedgerDetails';
import PlacesListPage from './pages/PlacesListPage';
import GuestsList from './pages/Guests/GuestsList';
import ListAssetsPage from './pages/Assets/ListAssetsPage';
import PartnerListAssetsPage from './Partner/Assets/PartnerListAssetsPage';
import LailaClients from './pages/Laila/Laila';
import LailaRemote from './pages/Laila/LailaRemote';
import ListConversationsCodes from './pages/ConversationsCodes/ListConversationsCodes';
import EditOrAddConvesation from './pages/ConversationsCodes/EditOrAddConvesation';
import Developers from './pages/Developers/Developers';
import EditBookingVariants from './pages/Bookings/EditBookingVariants';
import AdminFinancesList from './pages/Finanaces/FinancesList';

// Auth
// import { useAuth } from './hooks/useAuth';
// import { AuthProvider } from './hooks/useAuth';

// ----------------------------------------------------------------------
document.title = "Motherboard";
export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <AdminLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'bookings', element: <ListBookingsPage /> },
        { path: 'finances', element: <AdminFinancesList /> },
        { path: 'edit-booking-variants/:bookingId', element: <EditBookingVariants /> },
        { path: 'orders', element: <ListOrdersPage /> },
        { path: 'list-activities', element: <Activites /> },
        { path: 'partners', element: <ListPartnersPage /> },
        { path: 'assets', element: <ListAssetsPage /> },
        { path: 'employees', element: <ListPartnersEmployeePage /> },
        { path: 'coupons', element: <CouponDetails /> },
        { path: 'guests', element: <GuestsList /> },
        { path: 'places', element: <PlacesListPage /> },
        { path: 'conversationCodes', element: <ListConversationsCodes /> },
        { path: 'conversationCodes/:code', element: <EditOrAddConvesation /> },
        { path: 'laila-clients', element: <LailaClients /> },
        { path: 'laila-remote/:socketID', element: <LailaRemote /> },
        { path: 'user', element: <User /> },
        { path: 'products/:slug', element: <Products /> },
        { path: 'activity', element: <ActivityPage /> },
        { path: 'blog', element: <Blog /> },
        { path: 'edit-activity/:slug', element: <EditActivityPage /> },
        { path: 'edit-itinerary/:slug', element: <EditItinerary /> },
        { path: 'edit-fulfillment-policies/:slug', element: <EditFulfillmentPolicy /> },
        { path: 'bookings/:id', element: <BookingDetailsPage /> },
        { path: 'make-booking', element: <MakeBooking /> },
        { path: 'create-order', element: <CreateOrderPage /> },
        { path: 'update-inventory', element: <UpdateInventoryPage /> },
        { path: 'developers', element: <Developers /> },
        {
          path: 'update-master-inventory',
          element: <UpdateMasterInventoryPage />,
        },
        {
          path: 'ladakh-itinerary',
          element: <LadakhItineraryPage />,
        },
      ],
    },
    // Partner routes
    {
      path: '/partner',
      element: <PartnerLayout />,
      children: [
        // { path: ':id/home', element: <PartnersOverview /> },
        { path: ':id/bookings', element: <PartnerBookingDetails /> },
        { path: ':id/employees', element: <PartnerEmployeeDetails /> },
        { path: ':id/ledger', element: <PartnerLedgerDetails /> },
        { path: ':id/assets', element: <PartnerListAssetsPage /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },

        { path: 'login', element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
