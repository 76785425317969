/* eslint-disable */
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, Typography, IconButton, Autocomplete, TextField, Chip, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
// components
import Page from '../components/Page';
import ActivityList from '../components/activityList/activityList';
import CreateGenericActivityParams from '../components/activityList/createGenericActivityParams';
import Gallery from '../components/gallery/gallery';
import Input from 'src/theme/overrides/Input';
import { API_URL } from 'src/utils/constants';
import { Link, useNavigate } from 'react-router-dom';
// import {
//   ProductSort,
//   ProductList,
//   ProductCartWidget,
//   ProductFilterSidebar
// } from '../sections/@dashboard/products';
// //
// import PRODUCTS from '../_mocks_/products';

// ----------------------------------------------------------------------

const activityTypes = [
  'stay',
  'activity'
]
const activityStatuses = [
  'live',
]
export default function EditActivityPage() {
  const [activities, setActivities] = useState([]);
  const [initialActivity, setInitialActivitity] = useState([]);
  const [newVariants, setNewVariants] = useState([]);
  const [productData, setProductData] = useState([]);
  const [banners, setBanners] = useState([]);
  const [activityCommonParams, setActivityCommonParams] = useState({});
  const [newbannerData, setBannerSelection] = React.useState([]);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [selectedActivityStatus, setSelectedActivityStatus] = useState('live');
  const [selectedActivityType, setSelectedActivityType] = useState('stay');
  const [destinations, setDestinations] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const navigate = useNavigate();
  //   let activityCommonParams = {};

  //   const setActivityCommonParams = (value) => {
  //     activityCommonParams = value;
  //   };

  const getActivities = async () => {
    try {
      const res = await axios.get(`${API_URL}/activity/mb`);
      setActivities(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useMemo(() => {
    const filtered = selectedDestination
      ? activities.filter((activity) => activity.destination == selectedDestination)
      : activities;

    const filteredByType = selectedActivityType ?
      filtered.filter((activity) => activity.type == selectedActivityType)
      : filtered;

    const filteredByStatus = selectedActivityStatus ?
      filteredByType.filter((activity) => activity.status == selectedActivityStatus)
      : filteredByType;
    setFilteredActivities(filteredByStatus);
  }, [destinations, selectedDestination, selectedActivityStatus, selectedActivityType]);

  useEffect(() => {
    if (!destinations.length) {
      axios
        .get(`${API_URL}/place/mb?isActive=true`)
        .then((res) => {
          setDestinations(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    getActivities();
  }, []);

  const onDestinationChange = (_, newValue) => {
    if (newValue?.id) {
      setSelectedDestination(newValue?.label || null);
    } else {
      setSelectedDestination(newValue);
    }
  };

  const openInNewTab = (slug) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: '/dashboard/edit-activity/' + slug,
    }).click();
    openPassportInNewTab(slug);
  };

  const openPassportInNewTab = (slug) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: 'https://www.passprt.co/activity/' + slug,
    }).click();
  };

  return (
    <Page title="Activities">
      <Container>
        <Typography
          className='flex items-center justify-between'
          variant="h4" sx={{ mb: 5 }}>
          <p>Activities</p>
          <Button
            className='bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg w-40 text-center h-10 ml-auto'
            onClick={() => navigate('/dashboard/activity')}>
            Add Activity
          </Button>
        </Typography>
        <div className='mb-3 grid grid-cols-3'>
          <div>
            <label>Destination</label>
            <Autocomplete
              id="combo-box-demo"
              options={destinations}
              sx={{ width: 340 }}
              renderInput={(params) => <TextField {...params} label="" />}
              value={selectedDestination}
              onChange={onDestinationChange}
            />
          </div>
          <div>
            <label>Type</label>
            <Autocomplete
              id="combo-box-demo"
              options={activityTypes}
              sx={{ width: 340 }}
              renderInput={(params) => <TextField {...params} label="" />}
              value={selectedActivityType}
              onChange={(_, newValue) => { setSelectedActivityType(newValue) }}
            />
          </div>
          <div>
            <label>Status</label>
            <Autocomplete
              id="combo-box-demo"
              options={activityStatuses}
              sx={{ width: 340 }}
              renderInput={(params) => <TextField {...params} label="" />}
              value={selectedActivityStatus}
              onChange={(_, newValue) => { setSelectedActivityStatus(newValue) }}
            />
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 768 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Destination</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Slug</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredActivities.map((activity) => {
                // console.log(activity);
                return (
                  <TableRow
                    key={activity.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">
                    {activity.destination}
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                      {activity.label}
                    </TableCell>
                    <TableCell>{activity.slug}</TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        className='text-white'
                        label={activity.status?.charAt(0).toUpperCase() + activity.status?.slice(1)}
                        color={activity.status == 'live'
                          ? 'success'
                          : activity.status == 'draft' ?
                            'warning' :
                            'error'}
                      />
                    </TableCell>
                    <TableCell className='grid grid-cols-1 gap-2'>
                      <div className='flex items-center justify-between'>

                        <IconButton
                          aria-label="delete"
                          onClick={() => openInNewTab(activity.slug)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => openPassportInNewTab(activity.slug)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </div>
                      {(activity?.itineraryView || activity?.fulfillmentPolicy) && (<div className='flex flex-col items-center justify-center jus gap-2 w-full '>
                        {((activity?.itineraryView && activity?.itineraryView.length > 0) || activity?.type === 'taxi-tour') && <Link
                          className="bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg"
                          target='_blank' to={'/dashboard/edit-itinerary/' + activity.slug}
                        >
                          Itinerary
                        </Link>}
                        <Link
                          className="bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg whitespace-nowrap"
                          target='_blank' to={'/dashboard/edit-fulfillment-policies/' + activity.slug}
                        >
                          Fulfillment Policy
                        </Link>
                      </div>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page >
  );
}
